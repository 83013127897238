import React, { useState, useRef } from "react";
import { styled } from "../../stitches.config";
import Link from "../../components/Link";
// import List from '../../components/composition-components/box/List';
import { ReactComponent as LiveIcon } from "../../assets/img/live.svg?react";

import { useWindowWidth } from "../../helpers";
import Box from "../../components/composition-components/Box";
import NewsList from "./NewsList";

interface IPost {
  background: string;
  background_image: string;
  category: string;
  label: string;
  link: string;
  lockAt: number;
  locked: string;
  media: any;
  partner: string;
  player: string;
  search: string;
  show_author: boolean;
  show_links?: string;
  show_partner_logo?: boolean;
  title_normal: string;
}

interface IAd {
  text: string;
  link: string;
}
interface IList {
  label: string;
  postCategoryId: number | null;
  posts: Array<{
    link: string;
    title: string;
    labels?: string[];
    time: string;
  }>;
}
interface ISettings {
  b1: IPost;
  b2: IPost;
  b3: IPost;
  b4: IPost;
  b5: IPost;
  b6: IPost;
  b7: IAd;
  bList: {
    lists: IList[];
  };
}

interface IBlock6ListProps {
  settings: ISettings;
  blockGroupHasBackground?: string;
  blockSettings: {
    tb_region?: string;
  };
}

const Block6List: React.FC<IBlock6ListProps> = ({ settings, blockSettings, ...props }) => {
  const { bList } = settings;
  const windowWidth = useWindowWidth();

  return (
    <Root>
      <div className="container">
        <div className="row">
          <div className="col-md-9" data-tb-region={blockSettings?.tb_region}>
            <div className="row" style={{ paddingBottom: "15px" }}>
              <div className="col-md-4">
                <Box
                  id="b1"
                  type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                  settings={settings.b1}
                  style={props.blockGroupHasBackground ? 'with-padding' : null}
                />
              </div>

              <div className="col-md-4">
                <Box
                  id="b2"
                  type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                  settings={settings.b2}
                  style={props.blockGroupHasBackground ? 'with-padding' : null}
                />
              </div>
              <div className="col-md-4">
                <Box
                  id="b3"
                  type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                  settings={settings.b3}
                  style={props.blockGroupHasBackground ? 'with-padding' : null}

                />
              </div>
            </div>
            <div className="row bottom-row" style={{ padding: "15px 0" }}>
              <div className="col-md-4">
                <Box
                  id="b4"
                  type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                  settings={settings.b4}
                  style={props.blockGroupHasBackground ? 'with-padding' : null}
                />
              </div>

              <div className="col-md-4">
                <Box
                  id="b5"
                  type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                  settings={settings.b5}
                  style={props.blockGroupHasBackground ? 'with-padding' : null}

                />
              </div>
              <div className="col-md-4">
                <Box
                  id="b6"
                  type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                  settings={settings.b6}
                  style={props.blockGroupHasBackground ? 'with-padding' : null}

                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <NewsList
              lists={bList.lists}
              style={props.blockGroupHasBackground ? 'with-background' : null}
              tbRegion={blockSettings?.tb_region}
              settings={settings.b7}
            />
          </div>
        </div>
      </div>
    </Root>
  );
};

const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  margin: "40px 0",
  // width: '100%',
  height: "auto",
  justifyContent: "space-between",
  "@bp4": {
    flexDirection: "row",
    // paddingBottom: "100px",
  },
  ".bottom-row": {
    marginTop: "-30px",
    "@bp4": {
      marginTop: "0px",
    },
  }
});

export default Block6List;
